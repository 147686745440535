import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { loginService } from "../../services/authService";
import { useState } from "react";
import { useAuthData } from "../../routes/AuthDataContext";
import { getUserProfileService } from "../../services/userService";
import { useNavigate } from 'react-router-dom';
import { alertError } from "../../utils/notification";

import logo from "./NCM-Logo.webp"


interface FormData {
    username: string;
    password: string;
}

const LoginPage: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
    const [loading, setLoading] = useState(false);
    const { setUserData } = useAuthData();
    const navigate = useNavigate();

    const onSubmit = async (data: FormData) => {
        try {
            setLoading(true);

            const response = await loginService(data);
            const accessToken = response.access_token;
            

            if(response?.message === 'Invalid credentials'){
                alertError('Invalid credentials');
                return;
            }

            if(!response?.message && response.access_token){
                await localStorage.setItem('access_token', accessToken);

                // Fetch profile data
                const profileData = await getUserProfileService();
                setUserData(profileData);

                navigate('/clipping');

                return;
            }

            alertError('login process fail');
            

        } catch (error : any) {
             console.error('Login failed:', error.message);
             alertError('Service Error!');
        } finally {
            setLoading(false);
        }
      };

    return (
        <div className="bg-overlay bg-light vh-100 vw-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100 py-3">
                                    <div className="card my-auto">
                                        <div className="row g-0">
                                            <div className="col-lg-6 d-none d-lg-flex align-items-center" style={{ background : "#0c768a"}}>
                                                <div className="px-4">
                                                    <img className="img-fluid" src={logo} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="p-4">
                                                    <div className="text-center mb-5">
                                                        <h4>Welcome !</h4>
                                                        <p>Sign in to continue to NCM.</p>
                                                    </div>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                            <TextField
                                                                className="w-100 mb-3"
                                                                {...register('username', { required: 'Username is required' })}
                                                                label="Username"
                                                                variant="outlined"
                                                                value={'myncmadmin01'}
                                                                error={Boolean(errors.username)}
                                                                helperText={errors.username ? errors.username.message : ''}
                                                                disabled={loading}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { color: '#47484A', fontSize: '16px', fontWeight: 'bold' }
                                                                  }}
                                                            />
                                                            
                                                            <TextField
                                                                className="w-100 mb-3"
                                                                {...register('password', { required: 'Password is required' })}
                                                                label="Password"
                                                                type="password"
                                                                variant="outlined"
                                                                value={'admin2024'}
                                                                error={Boolean(errors.password)}
                                                                helperText={errors.password ? errors.password.message : ''}
                                                                disabled={loading}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    style: { color: '#47484A', fontSize: '16px', fontWeight: 'bold' }
                                                                  }}
                                                            />
                                                            <Button 
                                                                className="w-100" 
                                                                type="submit" 
                                                                variant="contained" 
                                                                color="primary" 
                                                                disabled={loading} 
                                                            >
                                                                {loading ? 'Loading...' : 'Sign In'}
                                                            </Button>
                                                        </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;


